import { ReactComponent as DisableIconImage } from '@/assets/Disable.svg';
import { ReactComponent as EnableIconImage } from '@/assets/Enable.svg';
import { ReactComponent as SubmitIconImage } from '@/assets/Submit.svg';
import { ReactComponent as ArchiveIconImage } from '@/assets/Archive.svg';
import { ReactComponent as UnarchiveIconImage } from '@/assets/Unarchive.svg';
import { ReactComponent as ApproveIconImage } from '@/assets/Approve.svg';
import { ReactComponent as RejectIconImage } from '@/assets/Reject.svg';
import { ReactComponent as ViewJSONIconImage } from '@/assets/View-JSON.svg';
import { ReactComponent as HistoryIconImage } from '@/assets/History.svg';
import { ReactComponent as EditJSONIconImage } from '@/assets/Edit-JSON.svg';
import { ReactComponent as UnpublishIconImage } from '@/assets/Unpublish.svg';
import { ReactComponent as ExportIconImage } from '@/assets/Export.svg';
import { ReactComponent as CloneIconImage } from '@/assets/Clone.svg';
import { ReactComponent as ActivateIconImage } from '@/assets/Activate.svg';
import { ReactComponent as InactivateIconImage } from '@/assets/Inactivate.svg';
import { ReactComponent as PublishIconImage } from '@/assets/Publish.svg';
import { ReactComponent as PreviewIconImage } from '@/assets/Preview.svg';
import { ReactComponent as CopyURLIconImage } from '@/assets/Copy-URL.svg';
import { ReactComponent as EditIconImage } from '@/assets/Edit.svg';
import { ReactComponent as PromoteIconImage } from '@/assets/Promote.svg';
import { ReactComponent as ViewIconImage } from '@/assets/View-Detail.svg';
import { ReactComponent as MoveIconImage } from '@/assets/Move.svg';
import { ReactComponent as DeleteIconImage } from '@/assets/Delete.svg';
import { ReactComponent as PauseIconImage } from '@/assets/Pause.svg';
import { ReactComponent as CompleteIconImage } from '@/assets/Complete.svg';
import { ReactComponent as WithdrawIconImage } from '@/assets/Send-to-Withdraw.svg';
import { ReactComponent as UnwithdrawIconImage } from '@/assets/Send-to-Draft.svg';
import { ReactComponent as WhiteUnwithdrawIconImage } from '@/assets/Send-to-Draft-White.svg';

export const ActionIconButton = {
  DisableIconImage,
  SubmitIconImage,
  ArchiveIconImage,
  UnarchiveIconImage,
  ApproveIconImage,
  RejectIconImage,
  ViewJSONIconImage,
  EditJSONIconImage,
  HistoryIconImage,
  UnpublishIconImage,
  ExportIconImage,
  CloneIconImage,
  ActivateIconImage,
  InactivateIconImage,
  PublishIconImage,
  CopyURLIconImage,
  EditIconImage,
  PreviewIconImage,
  EnableIconImage,
  PromoteIconImage,
  ViewIconImage,
  MoveIconImage,
  DeleteIconImage,
  PauseIconImage,
  CompleteIconImage,
  WithdrawIconImage,
  UnwithdrawIconImage,
  WhiteUnwithdrawIconImage,
};
