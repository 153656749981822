import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd/es/grid';
import Button from 'antd/es/button';
import { ReactComponent as SubmitIcon } from '@/assets/icon_submit_white.svg';

interface RoleInformationModalProps {
  serviceInfoModal: boolean;
  setServiceInfoModal: (val: boolean) => void;
  currServiceInfo: string;
  setCurrServiceInfo: (val: string) => void;
  serviceInformation: any;
  availableService: any[];
  selectedService: any;
  onSubmit: () => void;
}

const RoleInformationModal = ({
  serviceInfoModal,
  setServiceInfoModal,
  currServiceInfo,
  setCurrServiceInfo,
  serviceInformation,
  availableService,
  selectedService,
  onSubmit,
}: RoleInformationModalProps) => {
  return (
    <Modal
      className="modal-service-role"
      title="Service Information"
      style={{ borderRadius: '20px' }}
      width={1200}
      closable
      closeIcon={<CloseCircleOutlined />}
      footer={null}
      centered
      visible={serviceInfoModal}
      onCancel={() => {
        setServiceInfoModal(!serviceInfoModal);
      }}
    >
      <Row className="role-info">
        <Col span={24}>
          <Row>
            <Col
              span={6}
              style={{
                border: '1px solid #e2e2e2',
                borderRight: '0.5px solid #e2e2e2',
              }}
            >
              <div className="header">CONTENT</div>
              {Object.keys(serviceInformation)
                .filter(service => {
                  return (
                    (availableService.indexOf(service) > -1 ||
                      service === selectedService) &&
                    serviceInformation[service].menu === 'content'
                  );
                })
                .map((serviceKey: string) => {
                  return (
                    <div
                      key={`sub_header_${serviceKey}`}
                      className={
                        currServiceInfo === serviceInformation[serviceKey].key
                          ? 'sub-header-active'
                          : 'sub-header'
                      }
                      onClick={() => {
                        setCurrServiceInfo(serviceInformation[serviceKey].key);
                      }}
                    >
                      {serviceInformation[serviceKey].label}
                    </div>
                  );
                })}
              <div className="header">B2B</div>
              {Object.keys(serviceInformation)
                .filter(service => {
                  return (
                    (availableService.indexOf(service) > -1 ||
                      service === selectedService) &&
                    serviceInformation[service].menu === 'b2b'
                  );
                })
                .map((serviceKey: string) => {
                  return (
                    <div
                      key={`sub_header_${serviceKey}`}
                      className={
                        currServiceInfo === serviceInformation[serviceKey].key
                          ? 'sub-header-active'
                          : 'sub-header'
                      }
                      onClick={() => {
                        setCurrServiceInfo(serviceInformation[serviceKey].key);
                      }}
                    >
                      {serviceInformation[serviceKey].label}
                    </div>
                  );
                })}
            </Col>
            <Col
              span={18}
              style={{
                border: '1px solid #e2e2e2',
                borderLeft: '0.5px solid #e2e2e2',
              }}
            >
              <div style={{ padding: '30px', position: 'relative' }}>
                {serviceInformation[currServiceInfo].children}
                <Button
                  className="button-primary"
                  onClick={onSubmit}
                  style={{
                    position: 'absolute',
                    right: '60px',
                    bottom: '50px',
                    width: '234px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px 10px',
                  }}
                >
                  <SubmitIcon
                    style={{
                      marginRight: '10px',
                      color: 'white',
                    }}
                  />
                  <span style={{ fontWeight: 'bold' }}>
                    Select This Service
                  </span>
                </Button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default RoleInformationModal;
