import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { authStorage, keycloak } from './config';
import { AuthCredential } from 'swuif';
// import { parseParam } from './utils/utils';
import '@/style/app.less';
import { sbaas_stcms_api } from './config/sbaas';
import { activity } from './components/ActivityMonitoring';
import { extractEmail, isRoleExist } from './config/utils';
import { GlobalContextProvider } from './common/GlobalContext/context';

if (
  window.location.origin !== 'https://stcms-d.samsungiots.com' &&
  window.location.origin !== 'http://localhost:3000'
) {
  console.log = function() {
    // eslint-disable-next-line
  }.bind(console.log);
  console.info = function() {
    // eslint-disable-next-line
  }.bind(console.info);
  console.warn = function() {
    // eslint-disable-next-line
  }.bind(console.warn);
}

keycloak.instance
  .init({
    onLoad: 'check-sso',
    checkLoginIframe: false,
  })
  .then(async authenticated => {
    const userInfo: any = keycloak.instance.tokenParsed;
    let status = 'login';
    // const params = parseParam(window.location.href);
    // const skipAsk: boolean = params['skipAsk'] || false;
    if (authenticated && userInfo) {
      let dept = '';
      if (userInfo.email.indexOf('@smartthings') > -1) dept = 'SmartThings';
      else if (userInfo.department) {
        const deptArr = userInfo.department.split('/');
        dept = deptArr[deptArr.length - 1].trim();
      }
      const userCredential: AuthCredential = {
        key: userInfo['preferred_username'] || '',
        user: {
          username: userInfo['preferred_username'] || '',
          userId: userInfo.sub || '',
          fullName: userInfo['name'] || userInfo['given_name'] || '',
          firstName: userInfo['given_name'] || '',
          lastName: userInfo['family_name'] || '',
          email: extractEmail(userInfo['email']) || '',
          roles: userInfo.role || {},
          operation: (userInfo.role && userInfo.role.operation) || false,
          department: dept,
          originCountry: userInfo.origin_country || '',
          registration_status: userInfo['registration_status'] || '',
          countries: userInfo.countries || {},
          authTime: userInfo.auth_time || {},
          gseReadOnly:
            userInfo.role &&
            (userInfo.role.gse === 'GLOBAL_VIEWER' ||
              userInfo.role.gse === 'COUNTRY_VIEWER')
              ? true
              : false,
          gseVendor: userInfo.gseVendor || undefined,
        },
      };
      if (
        userInfo['registration_status'] &&
        (userInfo['registration_status'] === 'ACTIVE' ||
          userInfo['registration_status'] === 'APPROVED') &&
        isRoleExist(userInfo.role)
      )
        status = 'main';
      sbaas_stcms_api
        .script('update-last-login')
        .put({ query: { username: userInfo['preferred_username'] } });
      await authStorage.store(userCredential, true);
      activity.setUser(
        userCredential.user,
        window.screen.availHeight,
        window.screen.availWidth,
      );
    } else {
      if (window.location.pathname.includes('/user/edit')) {
        localStorage.setItem('userApproveLink', window.location.pathname);
      }
      await authStorage.store(undefined, false);
      activity.setUser();
    }

    ReactDOM.render(
      <GlobalContextProvider>
        <App status={status} />
      </GlobalContextProvider>,
      document.getElementById('root'),
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
