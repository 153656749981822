import { CloseOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import Checkbox from 'antd/es/checkbox/Checkbox';
import debounce from 'lodash.debounce';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useEventCallback } from 'swuif';
import { insertSortedArray } from '@/clients/utils';

export type country = {
  code: string;
  name: string;
};

interface CountryFormProps {
  languageForm?: boolean;
  countryList: country[];
  countryMap: any;
  selectedCountryList: any[];
  disabledCountryList?: any[];
  onChange: (countries: country[]) => void;
  disabled?: boolean;
  id?: string;
  titleMessageValidator?: any;
}

const CountryFieldTwoSide = ({
  languageForm = false,
  countryList,
  countryMap,
  selectedCountryList,
  disabledCountryList,
  onChange,
  disabled,
  id,
  titleMessageValidator,
}: CountryFormProps) => {
  const [allCountries, setAllCountries] = useState<any[]>([]);
  const [allSelectedCountries, setAllSelectedCountries] = useState<any[]>([]);
  const [allDisabledCountries, setAllDisabledCountries] = useState<any[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<any[]>([]);
  const [filteredSelectedCountries, setFilteredSelectedCountries] = useState<
    any[]
  >([]);
  const [checkedCountry, setCheckedCountry] = useState({});
  const [filterValue, setFilterValue] = useState({ left: '', right: '' });

  const onChangeCheckbox = (value: any, country: any) => {
    if (value) {
      const temp = insertSortedArray(allSelectedCountries, country, 'code');
      setFormValue(temp);
      setAllSelectedCountries(temp);
      if (
        country.code
          .toString()
          .toLowerCase()
          .indexOf(filterValue.right.toString().toLowerCase()) > -1 ||
        country.name
          .toString()
          .toLowerCase()
          .indexOf(filterValue.right.toString().toLowerCase()) > -1
      )
        setFilteredSelectedCountries(
          insertSortedArray(filteredSelectedCountries, country, 'code'),
        );
    } else {
      setAllSelectedCountries(
        allSelectedCountries.filter((c: any) => c.code !== country.code),
      );
      setFilteredSelectedCountries(
        filteredSelectedCountries.filter((c: any) => c.code !== country.code),
      );
    }
    setCheckedCountry({ ...checkedCountry, [country.code]: value });
  };

  const onDelete = (country: any) => {
    setCheckedCountry({ ...checkedCountry, [country.code]: false });
    setFilteredSelectedCountries(
      filteredSelectedCountries.filter(c => c.code !== country.code),
    );
    setAllSelectedCountries(
      allSelectedCountries.filter(c => c.code !== country.code),
    );
  };

  const setFormValue = useMemo(
    () =>
      debounce(selected => {
        onChange(selected);
      }, 500),
    [onChange],
  );

  const filtered = (countryList: any[], filterValue: string) => {
    return countryList.filter(
      c =>
        c.code
          .toString()
          .toLowerCase()
          .indexOf(filterValue.toString().toLowerCase()) > -1 ||
        c.name
          .toString()
          .toLowerCase()
          .indexOf(filterValue.toString().toLowerCase()) > -1,
    );
  };

  const filterCountry = useEventCallback((val: any, type: any) => {
    setFilterValue({ ...filterValue, [type]: val });
  });

  const debouncedfilterCountry = useMemo(
    () =>
      debounce((val: any, type: 'left' | 'right') => {
        filterCountry(val, type);
      }, 200),
    [filterCountry],
  );

  useEffect(() => {
    let checked: any = {};
    let selectedList: any[] = [];
    if (!selectedCountryList) selectedCountryList = [];
    for (let selected of selectedCountryList) {
      checked[selected] = true;
      if (countryMap[selected])
        selectedList.push({ code: selected, name: countryMap[selected] });
    }
    setCheckedCountry(checked);
    setAllCountries(countryList);
    setAllSelectedCountries(selectedList);
  }, [countryList, countryMap, selectedCountryList]);

  useEffect(() => {
    let disabledList: any[] = [];
    if (disabledCountryList) disabledList = disabledCountryList;
    setAllDisabledCountries(disabledList);
  }, [disabledCountryList]);

  useEffect(() => {
    setFilteredCountries(filtered(allCountries, filterValue.left));
  }, [allCountries, filterValue.left]);

  useEffect(() => {
    setFilteredSelectedCountries(
      filtered(allSelectedCountries, filterValue.right),
    );
  }, [allSelectedCountries, filterValue.right]);

  useEffect(() => {
    setFormValue(allSelectedCountries);
  }, [allSelectedCountries, setFormValue]);

  return (
    <Fragment>
      <Row>
        <Col span={24}>
          {allSelectedCountries
            ? allSelectedCountries.length > 1
              ? allSelectedCountries.length +
                (languageForm ? ' Languages' : ' Countries')
              : allSelectedCountries.length +
                (languageForm ? ' Language' : ' Country')
            : ''}
        </Col>
      </Row>
      <Row style={{ marginBottom: '10px' }}>
        <Col span={12} style={{ paddingRight: '5px' }}>
          <Input
            onChange={e => debouncedfilterCountry(e.target.value, 'left')}
            className="input-country-filter"
          />
        </Col>
        <Col span={12} style={{ paddingLeft: '5px' }}>
          <Input
            onChange={e => debouncedfilterCountry(e.target.value, 'right')}
            className="input-country-filter"
          />
        </Col>
      </Row>
      <Row>
        <Col span={12} style={{ paddingRight: '5px' }}>
          <div
            className="country-list-outer"
            style={{
              border:
                titleMessageValidator &&
                id &&
                titleMessageValidator[id] &&
                'thin solid #ff5050',
            }}
          >
            <div className="country-list-container">
              {filteredCountries.map((country: any) => (
                <Row key={country.code}>
                  <Checkbox
                    key={country.code}
                    onChange={event => {
                      onChangeCheckbox(event.target.checked, country);
                    }}
                    checked={checkedCountry[country.code]}
                    disabled={
                      disabled ||
                      allDisabledCountries.indexOf(country.code) > -1
                    }
                  >{`${country.name} (${country.code})`}</Checkbox>
                </Row>
              ))}
            </div>
          </div>
          <span className="ant-form-item-explain-error">
            {titleMessageValidator && id && titleMessageValidator[id]}
          </span>
        </Col>
        <Col span={12} style={{ paddingLeft: '5px' }}>
          <div
            className="country-list-outer"
            style={{
              border:
                titleMessageValidator &&
                id &&
                titleMessageValidator[id] &&
                'thin solid #ff5050',
            }}
          >
            <div className="country-list-container">
              {filteredSelectedCountries.map((country: any) => (
                <div key={country.code}>
                  {allDisabledCountries.indexOf(country.code) > -1 ? (
                    <label
                      className="right-country-list-label"
                      style={{
                        display: 'inline-flex',
                        width: '100%',
                        cursor: 'not-allowed',
                      }}
                    >
                      <span
                        style={{ width: '100%' }}
                      >{`${country.name} (${country.code})`}</span>
                    </label>
                  ) : (
                    <label
                      onClick={() => {
                        if (!disabled) onDelete(country);
                      }}
                      className="right-country-list-label"
                      style={{
                        display: 'inline-flex',
                        width: '100%',
                        cursor: disabled ? 'not-allowed' : 'pointer',
                      }}
                    >
                      <span
                        style={{ width: '100%' }}
                      >{`${country.name} (${country.code})`}</span>

                      <CloseOutlined
                        className={`close-icon`}
                        style={{
                          right: '0px',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      />
                    </label>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CountryFieldTwoSide;
